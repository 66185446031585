* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

input {
  outline: none;
}

.addAdvertPage {
  scroll-behavior: smooth;
}

.cardShadow {
  box-shadow: 0px 83.9614px 173.52px rgba(21, 34, 66, 0.05),
    0px 25.3119px 52.3113px rgba(21, 34, 66, 0.0325794),
    0px 10.5133px 21.7274px rgba(21, 34, 66, 0.025),
    0px 3.80244px 7.85838px rgba(21, 34, 66, 0.0174206);
}
.swiperPaginationStyle .swiper-pagination-bullet {
  background: white;
  border: 1.12px solid #152242;
  opacity: 0.4;
}
.swiperPaginationStyle .swiper-pagination-bullet-active {
  background: white;
  opacity: 1;
}

.swiperWithoutPagination .swiper-pagination-bullet {
  display: none;
}

/* .swiper-pagination-bullet {
  display: none !important;
}

.swiper-pagination-bullet-active {
  display: none !important;
} */

.swiper-button-next {
  display: none !important;
}

.swiper-button-prev {
  display: none !important;
}

.ownProgress .ant-progress-inner {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 0 !important;
  width: 140px !important;
}

.ownProgress .ant-progress-text {
  position: static !important;
  line-height: 0 !important;
  margin: 10px 0 !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #152242 !important;
}

.ownProgress .ant-progress-circle {
  width: 60px !important;
  height: 55px !important;
}

@media screen and (max-width: 700px) {
  .ownProgress .ant-progress-inner {
    width: 100px !important;
  }
  .ownProgress .ant-progress-text {
    margin: 4px 0;
    font-size: 24px;
  }

  .ownProgress .ant-progress-circle {
    width: 35px !important;
    height: 40px !important;
  }
}

.halfRoundRadio .ant-radio-button-wrapper {
  border-start-start-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-end-start-radius: 0px;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border: none;
  border-radius: 30px !important;
  margin: 5px 10px 5px 0;
  background-color: #f0f0f0;
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 400;
  height: 35px;
  line-height: 33px;
}

.halfRoundRadioMain .ant-radio-button-wrapper {
  border-start-start-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-end-start-radius: 0px;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border: none;
  border-radius: 30px !important;
  margin: 5px 20px 14px 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 35px;
  line-height: 33px;
  opacity: 600;
}

.halfRoundRadioMain .ant-radio-button-wrapper:hover {
  color: #152242;
  background-color: #f0f0f0;
}

.halfRoundRadioMain
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #152242;
  background-color: #f0f0f0;
}

.spaceBottom .ant-radio-button-wrapper {
  margin-bottom: 20px;
}

.halfRoundRadio .ant-radio-button-wrapper:not(:first-child)::before {
  content: none;
}

.halfRoundRadioMain .ant-radio-button-wrapper:not(:first-child)::before {
  content: none;
}

.fullRoundedRadio .ant-radio-button-wrapper:not(:first-child)::before {
  content: none;
}

.fullRoundedRadio .ant-radio-button-wrapper {
  border-start-start-radius: 0px;
  border-end-start-radius: 0px;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  border-radius: 9999px;
  margin: 0 10px;
  background-color: #f0f0f0;
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  width: 40px;
  line-height: 38px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #007bfb;
  border-color: #007bfb;
}

.ant-select-single .ant-select-selector {
  color: #152242 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.oneSelect.ant-select-single .ant-select-selector {
  height: 40px !important;
  border-radius: 10px !important;
  border: 1px solid rgba(21, 34, 66, 0.25) !important;
  align-items: center !important;
}

.mapPageBlockScroll::-webkit-scrollbar {
  width: 7px;
}

.mapPageBlockScroll::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;
}

.mapPageBlockScroll {
  scrollbar-width: thin;
  scrollbar-color: #d2d2d2;
}

.hamburger {
  cursor: pointer;
  width: 35px;
  height: 27px;
  transition: all 0.25s;
  position: relative;
  outline: none;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 3px;
  background: #007bfb;
  transform: rotate(0);
  transition: all 0.5s;
  border-radius: 20px;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

.large-checkbox .ant-checkbox-inner {
  width: 22px;
  height: 22px;
}

.ant-checkbox-group {
  display: contents;
}

.ant-checkbox-group-item {
  margin: 0 10px 14px 0;
  font-size: 18px !important;
}

.large-checkbox .ant-checkbox,
.large-checkbox .ant-checkbox-inner {
  width: 22px !important;
  height: 22px !important;
}

.ant-collapse-item {
  border-radius: 8px !important;
}
