@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.no-spin {
  -moz-appearance: textfield;
  appearance: textfield;
}
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spin input {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}
.no-spin input::-webkit-inner-spin-button,
.no-spin input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
